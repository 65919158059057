// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("trix");
require("@rails/actiontext");

// const Turbolinks = require("turbolinks")
// Turbolinks.start()

var $ = require("jquery");

import "bootstrap";
import { navbarAnimation } from "../components/navbar.js";
import Typed from "typed.js";
// import { googleTranslate } from '../components/googleTranslate.js'
import { carrouselCategory } from "../components/carrouselCategory";
import {
  animationScroll,
  animationScrollAll,
} from "../components/animationScroll";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// googleTranslate();
// document.addEventListener("turbolinks:load", (event) => {
navbarAnimation();

// });

const categories = document.querySelectorAll(".carousel-categories");

if (categories.length != 0) {
  // $('.carousel-categorie').carousel('dispose');
  carrouselCategory(categories);
} else {
  $(".carousel").carousel({
    interval: 2000,
  });
}

if (document.getElementById("map")) {
  animationScroll(".anchor-map");
}

var options = {
  typeSpeed: 50,
  backSpeed: 20,
  loop: true,
  showCursor: false,
};

var typedText = document.getElementById("typed");

if (typedText) {
  var typed = new Typed("#typed", {
    ...options,
    strings: JSON.parse(typedText.dataset.payload),
  });
}

const secondLevelPage = document.getElementById("collapse-page-second-level");

if (secondLevelPage) {
  const nodeListLink = secondLevelPage
    .querySelector("#collapseExample")
    .querySelectorAll("a");
  animationScrollAll(nodeListLink);
  $(".collapse").collapse({
    toggle: false,
  });
}

if (document.getElementById("myCollapsible")) {
  $("#myCollapsible").collapse({
    toggle: false,
  });
}

const btnGoogleTranslate = document.getElementById("button-google-translate");
if (btnGoogleTranslate) {
  btnGoogleTranslate.addEventListener("click", (event) => {
    document
      .getElementById("google_translate_element")
      .classList.toggle("slide-hidden");
  });
}
