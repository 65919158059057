const navbarAnimation = () => {
  const nav = document.querySelector('.navbar-key-paradise')
  window.addEventListener('scroll', (event) => {
    if (window.scrollY > 92) {
      if (nav.classList.contains('navbar-animation-scroll') != true) {
          nav.classList.add('navbar-animation-scroll')
      }
    } else if (window.scrollY < 92) {
      if (nav.classList.contains('navbar-animation-scroll') === true) {
          nav.classList.remove('navbar-animation-scroll')
      }
    }

  });
}

export { navbarAnimation }
