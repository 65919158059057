const animationScroll = (anchor) => {
  const element = document.querySelector(anchor);
  if (element) {
    element.addEventListener("click", function (e) {
      e.preventDefault();
      document.querySelector(this.getAttribute("href")).scrollIntoView({
        behavior: "smooth",
      });
    });
  }
};

const animationScrollAll = (nodeList) => {
  nodeList.forEach((element) => {
    element.addEventListener("click", function (e) {
      e.preventDefault();
      document.querySelector(this.getAttribute("href")).scrollIntoView({
        behavior: "smooth",
      });
    });
  });
};

export { animationScroll, animationScrollAll };
