const carrouselCategory = (categories) => {

  categories.forEach((categorie) => {

  const classCategory = categorie.classList[categorie.classList.length - 1]
  $(`.${classCategory}`).carousel({
        interval: 999999
      }, 'cycle');

  categorie.addEventListener('mouseover', (event) => {
      categorie.classList.add('slide')
      const classCategory = categorie.classList[categorie.classList.length - 1]
      console.log(classCategory)
      $(`.${classCategory}`).carousel('dispose');
      $(`.${classCategory}`).carousel({
        interval: 500
      }, 'cycle');
    })
    categorie.addEventListener('mouseleave', (event) => {
      categorie.classList.remove('slide')
      const classCategory = categorie.classList[categorie.classList.length - 1]
      $(`.${classCategory}`).carousel('dispose');
    })
  })
}

export { carrouselCategory }
